<template>
  <div class="full-width full-height bg-blue p-sm">
    <div
      class="flex items-center p-w-xs"
      style="height:100%"
    >
      <div class="f-l full-height text-white m-r-sm">
        <!-- <cameral-project-route /> -->
        <cameral-route
          :mode="1"
          @postCamera="postCameraHandle"
        />
      </div>
      <div
        class="f-r m-l-sm full-height text-white flex-one flex"
      >
        <div
          class="flex justify-between items-center m-b-xs"
        >
          <div class="flex items-center">
            <a-select
              default-value="four"
              style="width: 120px"
              class="m-r-sm"
              @change="handleChange"
            >
              <a-select-option value="four">
                4分屏
              </a-select-option>
              <a-select-option value="single">
                单分屏
              </a-select-option>
              <a-select-option value="six">
                6分屏
              </a-select-option>
              <a-select-option value="nine">
                9分屏
              </a-select-option>
              <a-select-option value="twelve">
                12分屏
              </a-select-option>
              <a-select-option value="sixteen">
                16分屏
              </a-select-option>
            </a-select>
            <a-button
              type="primary"
              class="m-r-sm"
              :disabled="current === 0"
              @click="prevHandle"
              >上一页</a-button
            >
            <a-button
              type="primary"
              class="m-r-sm"
              :disabled="nextDisabled"
              @click="nextHandle"
              >下一页</a-button
            >
            <a-icon
              type="desktop"
              title="全屏展示"
              class="pointer"
              @click="handleFullScreen"
            />
          </div>
          <back />
        </div>
        <div class="full-width flex items-center ht">
          <split-screen-com
            ref="splitScreenInfo"
            :size="videoSize"
            @delCamera="delCameraHandle"
          />
        </div>
      </div>
    </div>
    <transition name="animate">
      <full-screen
        v-if="showFullScreen"
        :videoSize="videoSize"
        :currentCameraList="currentCameraList"
        ref="fullScreen"
      ></full-screen>
    </transition>
  </div>
</template>

<script>
import Back from '@/components/Back'
import CameralRoute from '@/components/CameralRoute'
// import CameralProjectRoute from '@/components/CameralProjectRoute';
import SplitScreenCom from '@/components/SplitScreenCom'
import FullScreen from './components/FullScreen.vue'
import { mapState, mapMutations } from 'vuex'
/* const flatten2d = (arr) => {
    var result = [];
    for(var i = 0; i < arr.length; i++) {
        result = result.concat(arr[i]);
    }
    return result;
} */
export default {
  name: 'ProjectScreen',
  data() {
    return {
      videoSize: 4,
      current: 0,
      currentActiveIndex: 0,
      currentCameraList: [],
      showFullScreen: false
      // cameraListAll:[]
    }
  },
  components: {
    // CameralRoute,
    // CameralProjectRoute,
    CameralRoute,
    Back,
    SplitScreenCom,
    FullScreen
  },
  computed: {
    ...mapState({
      clickCameraList: state => state.home.clickCameraList
    }),
    nextDisabled() {
      // console.log('tata',this.current,this.clickCameraList.length);
      return this.current >= this.clickCameraList.length
    }
  },
  watch: {
    /* clickCameraList(newValue) {
      this.$nextTick(() => {
        let cameraList;
        if (newValue.length > this.videoSize) {
          let startIndex = newValue.length - this.videoSize;
          cameraList = newValue.slice(startIndex);
        } else {
          cameraList = newValue;
        }
        this.playVideo(cameraList);
      });
    }, */
  },
  mounted() {},
  methods: {
    postCameraHandle(camera) {
      if (camera.type !== 'camera') {
        return
      }
      this.currentCameraList =
        this.clickCameraList[this.current] || []
      this.currentCameraList[
        this.currentActiveIndex
      ] = camera

      this.currentActiveIndex =
        this.currentActiveIndex >= this.videoSize - 1
          ? 0
          : ++this.currentActiveIndex
      // console.log('haha', this.currentCameraList);
      // debugger;
      this.$set(
        this.clickCameraList,
        this.current,
        this.currentCameraList
      )
      // let cameraListAll = flatten2d(this.clickCameraList);
      // console.log('total',this.clickCameraList);
      this.setClickCameraList(this.clickCameraList)

      this.playVideo(
        this.currentCameraList,
        this.currentActiveIndex
      )
    },
    handleChange(value) {
      //清空摄像机数据
      // this.setClickCameraList([]);
      // this.clearPlayList();

      switch (value) {
        case 'single':
          this.videoSize = 1
          this.playCurrentVideoList(this.videoSize)
          break
        case 'four':
          this.videoSize = 4
          this.playCurrentVideoList(this.videoSize)
          break
        case 'six':
          this.videoSize = 6
          this.playCurrentVideoList(this.videoSize)
          break
        case 'nine':
          this.videoSize = 9
          this.playCurrentVideoList(this.videoSize)
          break
        case 'twelve':
          this.videoSize = 12
          this.playCurrentVideoList(this.videoSize)
          break
        case 'sixteen':
          this.videoSize = 16
          this.playCurrentVideoList(this.videoSize)
          break
      }
      /* this.screenMode = value;
      this.url = this.url.map((item) => {
        let patern = item.substring(item.indexOf('#') + 1);
        // console.error('hah', patern);
        return item.replace(patern, `${this.screenMode}-window`);
      }); */
    },
    playCurrentVideoList(videoSize) {
      //console.error(this.clickCameraList,this.clickCameraList[this.current].filter(item=>!!item).slice(0,videoSize),videoSize);
      this.current = 0
      this.clearPlayList()
      this.currentCameraList = this.clickCameraList[
        this.current
      ]
        ? this.clickCameraList[this.current]
            .filter(item => !!item)
            .slice(0, videoSize)
        : []
      console.error(this.currentCameraList)
      let clickCameraList = this.clickCameraList.slice(0, 1)
      this.$set(
        clickCameraList,
        this.current,
        this.currentCameraList
      )
      this.setClickCameraList(clickCameraList)
      this.playVideo(this.currentCameraList, 0)
    },
    playVideo(cameraList, currentIndex) {
      this.$refs.splitScreenInfo.setActiveBorder(
        currentIndex
      )
      this.$refs.splitScreenInfo.setCameraList(cameraList)
    },
    delCameraHandle(cameraList) {
      /* this.clickCameraList.splice(index, 1); */
      /*  delete this.clickCameraList[index];
      let clickCameraList = this.clickCameraList.filter((item) => item); */
      /*  console.log('dd', this.clickCameraList); */
      /*   let newCameraList = cameraList.filter((item) => item); */
      // console.log(cameraList,'papa');
      this.currentCameraList = cameraList
      this.$set(
        this.clickCameraList,
        this.current,
        cameraList
      )
      this.setClickCameraList(this.clickCameraList)
      // this.$refs.splitScreenInfo.clearScreen(this.videoSize); fixsxy
      this.$refs.splitScreenInfo.setCameraList(cameraList)
    },
    nextHandle() {
      this.current++
      // this.clearPlayList();
      this.prevOrNextPlay()
    },
    prevHandle() {
      this.current--
      this.prevOrNextPlay()
    },
    prevOrNextPlay() {
      this.clearPlayList()
      this.currentCameraList = this.clickCameraList[
        this.current
      ]
      this.playVideo(this.currentCameraList, 0)
    },
    clearPlayList() {
      if (this.$refs.splitScreenInfo) {
        this.$refs.splitScreenInfo.clearScreen(
          this.videoSize
        )
        this.$refs.splitScreenInfo.setActiveBorder(0)
        this.currentActiveIndex = 0
      }
    },
    handleFullScreen() {
      this.setFullScreen(true)
      this.$nextTick(() => {
        let ele = this.$refs['fullScreen'].$el
        if (ele.requestFullscreen) {
          ele.requestFullscreen()
        } else if (ele.mozRequestFullScreen) {
          ele.mozRequestFullScreen()
        } else if (ele.webkitRequestFullScreen) {
          ele.webkitRequestFullScreen()
        }
      })
    },
    setFullScreen(fullScreen) {
      this.showFullScreen = fullScreen
    },
    ...mapMutations(['setClickCameraList'])
  },
  destroyed() {
    this.setClickCameraList([])
    this.clearPlayList()
  }
}
</script>

<style scoped>
.f-l {
  width: 26%;
  background: linear-gradient(
    0deg,
    rgba(15, 40, 61, 0.3),
    rgba(46, 86, 142, 0.3)
  );
}
/* .f-l,.f-r{
  box-shadow: 0 0 12px #a2e2fb;
} */
.w70 {
  width: 70%;
}
.f-r {
  flex-direction: column;
}
.ht {
  height: calc(100% - 20px);
}
.animate-enter-active,
.animate-leave-active {
  transition: opacity 0.3s;
}

.animate-enter, .animate-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
