<template>
  <div class="full-width full-height fix bg-blue text-white">
      <span class="absolute desktop pointer" @click="exitFull"><a-icon type="desktop" title="退出全屏" /></span>
      <split-screen-com
            ref="splitScreenInfo"
            :size="videoSize"
            @delCamera="delCameraHandle"
          />
  </div>
</template>

<script>
import SplitScreenCom from '@/components/SplitScreen';
export default {
  props: {
      videoSize: {
          type:Number,
          default:0
      },
      currentCameraList: {
          type:Array,
          default() {
              return [];
          }
      }
  },
  components: {
      SplitScreenCom
  },
  mounted() {
      if(this.$refs['splitScreenInfo']) {
          this.$refs['splitScreenInfo'].setCameraList(this.currentCameraList);
        //   this.$refs['splitScreenInfo'].setActiveBorder(-1);
      }
      document.addEventListener('fullscreenchange', this.exitHandler.bind(this));
  },
  methods: {
      exitFull() {
           
          this.$parent.setFullScreen(false);
      },
      delCameraHandle(cameraList) {
        //   console.log('haha');
          this.$parent.delCameraHandle(cameraList);
      },
      exitHandler() {  
          if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                ///fire your event
                this.exitFull();
          }
      }
  }
}
</script>

<style scoped>
.fix {
    left: 0;
    top:0;
    width:100%;
    height:100%;
    z-index: 999;
}
.desktop {
    z-index: 999;
    background: rgb(48, 116, 218);
    padding:5px 10px;
    border-radius: 5px;
    font-size: 18px;
    right:28px;
    top:0;
}
</style>