<template>
  <div
    class=" flex flex-wrap full-width  bd-s-b bd-s-l bd-s-r bd-s-t"
    id="wrap"
  >
    <drop
      class="screen-camera-item "
      v-for="(vo, key) in size"
      :key="key"
      :class="[
        'screen-item bd-r  width-' + size,
        {
          'bd-b': isBorder(key),
          currentBorder: currentBorderIndex === key
        }
      ]"
      :style="{ height: ht }"
      @drop="addScreenCamera($event, key)"
      @click.native="setCurrentActive(key)"
    >
      <template v-if="cameraList[key]">
        <div class="screen-camera-delete">
          <a-icon
            title="关闭"
            type="close"
            @click="removeScreenCamera(key)"
          />
        </div>
        <div
          class="screen-camera-title"
          v-show="cameraList[key].cameraName"
        >
          <span :title="cameraList[key].cameraName">{{
            cameraList[key].cameraName
          }}</span>
        </div>
        <div class="screen-camera-image">
          <flv-player
            :control="false"
            :ref="'flvPlay' + key"
            video-type="flv"
            @dblclick.native.stop="
              fullScreenDom('flvPlay' + key)
            "
          ></flv-player>
        </div>
        <div class="video-type">
          <toggle-resolution
          v-if="false"
            className="bg-mask"
            :resolutionValue="cameraList[key].videoType"
            @toggleResolutionHandle="
              value => handleVideoTypeChange(value, key)
            "
          ></toggle-resolution>
        </div>
      </template>
      <template v-else>
        <div class="screen-camera-image">
          <loading
            loading-status="empty"
            message="点击添加视频"
          ></loading>
        </div>
      </template>
    </drop>
  </div>
</template>

<script>
import flvPlayer from '@/components/module/camera/FlvPlayer'
import ToggleResolution from '@/components/module/camera/ToggleResolution'
import { Drop } from 'vue-drag-drop'
import { /* mapState, */ mapMutations } from 'vuex'

import Loading from '@/components/module/Loading'
import { setStorage } from '@/tools'
export default {
  name: 'SplitScreen',
  components: {
    flvPlayer,
    Drop,
    Loading,
    ToggleResolution
  },
  data() {
    return {
      getUrlLoading: false,
      cameraList: [],
      ht: 0,
      wrapWd: 0,
      currentBorderIndex: 0
    }
  },
  props: {
    /* screenType: {
      default() {
        return '';
      },
    }, */
    size: {
      type: Number,
      default() {
        return 4
      }
    },
    maxHt: {
      type: Number,
      default: 0
    }
    /*baseList:{
      default(){
        return [];
      }
    }*/
  },
  watch: {
    size(newValue, oldValue) {
      if (newValue !== oldValue) {
        // this.destroyVideo(this.size,true);fixsxy
        this.$nextTick(() => {
          // this.setWrapWidth();
          this.ht = this.getHeight() + 'px'
          this.totalHeight(this.getHeight())
        })
      }
    }
  },
  computed: {
    /* ...mapState(['Camera']), */
  },
  /*beforeUpdate(){
    if(this.baseList.length) this.cameraList = this.baseList;
  },*/
  mounted() {
    /*this.$root.$on("removeEventMarker", () => {
      if(!this.chosedMark){
        return false;
      }
      if(this.cameraMarkVisable){
        this.backMark.show();
        return false;
      }
      //console.info(this.chosedMark);
      this.chosedMark.show();
      this.removeMarker(this.backMark);

      this.$refs.dragMarkerGhost.$el.style.top = "-50px";
      this.$refs.dragMarkerGhost.$el.style.left = "-50px";

    });*/
    this.$nextTick(() => {
      this.ht = this.getHeight() + 'px'
      this.totalHeight(this.getHeight())
    })
  },
  methods: {
    // ...mapActions(['getCameraPlayUrl', 'setCameraVisitRecords']),
    ...mapMutations(['setMaxHeight']),
    setActiveBorder(currentBorderIndex) {
      // this.currentBorderIndex = this.currentBorderIndex >= this.size ? 0: ++this.currentBorderIndex;
      this.currentBorderIndex = currentBorderIndex
    },
    setCurrentActive(key) {
      this.currentBorderIndex = this.$parent.currentActiveIndex = key
    },
    setWidth(wd) {
      // debugger
      let dom = document.getElementById('wrap')
      if (dom) {
        dom.style.width = `${wd}px`
      }
    },

    totalHeight(ht) {
      switch (this.size) {
        case 1:
          this.setMaxHeight(ht * 1)
          break
        case 4:
        case 6:
          this.setMaxHeight(ht * 2)
          break
        case 9:
        case 12:
          this.setMaxHeight(ht * 3)
          break
        case 16:
          this.setMaxHeight(ht * 4)
          break
      }
    },
    getHeight() {
      let dom = document.querySelector('#wrap')

      let wd = dom.getBoundingClientRect().width
      switch (this.size) {
        case 1:
          return this.calHeight(wd, 1)
        case 4:
          return this.calHeight(wd, 2)
        case 6:
        case 9:
          return this.calHeight(wd, 3)
        case 12:
        case 16:
          return this.calHeight(wd, 4)
      }
    },
    calHeight(wd, size) {
      return ((wd / size) * 9) / 16
    },
    saveCameraList(list) {
      setStorage('cameraList', list)
    },
    isBorder(index) {
      if (this.size == 4) {
        if (index < this.size - 2) {
          return true
        }
      } else if (this.size == 6 || this.size == 9) {
        if (index < this.size - 3) {
          return true
        }
      } else if (this.size == 12 || this.size == 16) {
        if (index < this.size - 4) {
          return true
        }
      }
    },
    cameraDelete(key) {
      //console.info("cameraDelete", key);
      this.$emit('itemDelete', key)
    },
    setCameraList(list) {
      if (!list || !list.length) {
        this.cameraList.forEach((vo, idx) => {
          this.removeScreenCamera(idx)
        })
        this.cameraList = []
        return false
      }

      this.cameraList = []
      list.forEach((vo, idx) => {
        this.addScreenCamera(vo, idx)
      })
    },
    addScreenCamera(cameraInfo, key) {
      // console.log('drop', cameraInfo, this.cameraList, key);
      if (!cameraInfo) {
        if (this.$root.dragSelectCamera.cameraId) {
          cameraInfo = this.$root.dragSelectCamera
        } else {
          return false
        }
      }

      this.$root.$emit('removeEventMarker', '')
      if (this.cameraList.length >= this.screenSize) {
        this.$message.info('超过分屏数量限制！')
        return false
      }
      if (this.cameraList.indexOf(cameraInfo) >= 0) {
        this.$message.info('视频已存在！')
        return false
      }
      //this.screenSizeList.push(cameraInfo);

      this.cameraList[key] = cameraInfo

      this.saveCameraList(this.cameraList)
      this.$forceUpdate()
      //this.cameraList.push(cameraInfo);
      //this.cameraList = [...[cameraInfo]];
      this.$nextTick(() => {
        setTimeout(() => {
          let idx = key, //this.cameraList.length - 1,
            obj = this.$refs['flvPlay' + idx][0]
          // console.info('addScreenCamera', obj);

          this.cameraPlay(obj, cameraInfo)
        }, 500)
      })
      /*setTimeout(() => {
        let idx = key, //this.cameraList.length - 1,
          obj = this.$refs["flvPlay"][idx];
        console.info("addScreenCamera", obj);
        this.cameraPlay(obj, cameraInfo);
      },500);*/

      // console.info('addScreenCamera list', this.cameraList);
    },
    destroyVideo(size, flag) {
      for (let i = 0; i < size; i++) {
        this.removeScreenCamera(i, flag)
      }
    },
    clearScreen(size) {
      for (let i = 0; i < size; i++) {
        this.removeScreenCamera(i, true)
      }
    },
    removeScreenCamera(idx, flag) {
      let obj =
        this.$refs['flvPlay' + idx] &&
        this.$refs['flvPlay' + idx][0]
      if (obj) {
        obj.flv_destroy()
        // console.error('haha');
      }

      /* this.cameraList.splice(idx, 1);
      
      this.saveCameraList(this.cameraList);fix以前 */
      delete this.cameraList[idx]
      this.$forceUpdate()

      if (!flag) {
        //如果不是清屏操作，则不用删除摄像机
        console.error('是否清屏')
        this.$emit('delCamera', this.cameraList)
      }

      // console.error('删之后', this.cameraList);

      // console.info('removeScreenCamera', obj, this.cameraList);
    },
    pauseSCreenCamera(idx) {
      let obj = this.$refs['flvPlay' + idx][0]
      if (obj) {
        obj.flv_pause()
        // console.error('haha');
      }
    },
    startScreenCameral(idx) {
      let obj = this.$refs['flvPlay' + idx][0]
      if (obj) {
        obj.flv_play()
        // console.error('haha');
      }
    },
    cameraPlay(refObj, cameraInfo, change) {
      // console.log('获取播放器2');
      let that = this,
        cameraNum = cameraInfo.cameraNum
      //m3u8Url 播放器暂时有问题
      let mediatype = that.$root.mediatype // 'flvUrl';//rtmpUr flvUrl m3u8Url
      this.$api.home
        .getCameraPlayUrlTemp({
          cameraNum: cameraNum, //record.data.cameraName,
          videoType: cameraInfo.videoType || 0,
          mediatype: mediatype,
          source: cameraInfo.source
        })
        .then(res => {
          if (res.code === 200) {
            if (!res.data || !res.data.flv) {
              this.$message.error(
                '视频地址请求失败，' + res.message
              )
              //this.$emit("update:visible", false);
              return false
            }
            refObj.flv_Play(res.data.flv, true)
            if (!change) {
              //TODO: 增加摄像头访问记录
              // that.setCameraVisitRecords({
              //   cameraName: cameraInfo.cameraName,
              //   cameraNum: cameraInfo.cameraNum,
              //   organizationId: cameraInfo.organizationId,
              //   regionCode: cameraInfo.regionCode,
              //   roadId: cameraInfo.roadId
              //   //cameraType      : cameraInfo.cameraType,
              //   //classIfy        : cameraInfo.classIfy
              // });
            }
          }
        })
    },
    handleVideoTypeChange(value, index) {
      // console.log(value, index);
      this.$nextTick(() => {
        let idx = index,
          obj = this.$refs['flvPlay' + idx][0]
        let cameraInfo = this.cameraList[idx]
        cameraInfo.videoType = value
        //高清切换标清停掉高清流
        if (value === 0) {
          this.stopStreamSingleHandle(cameraInfo)
        }
        // cameraInfo.loading = true;
        this.$set(this.cameraList, idx, cameraInfo)

        // console.log('papa', this.cameraList, this.cacheCamera);
        this.cameraPlay(obj, cameraInfo)
      })
    },
    stopStreamSingleHandle(cameraInfo) {
      let cameraNums = [cameraInfo.cameraNum]
      let data = {
        cameraNums,
        streamType: -2
      }
      this.$api.home.stopStream(data).then(() => {
        /*  if (res.code) {
          console.log('停止高清推流');
        } */
      })
    },

    // 全屏dom
    fullScreenDom(ref) {
      const dom_flvWrap = this.$refs[ref]?.[0]?.$el

      if (dom_flvWrap) {
        if (
          document.getFullscreenElement() === dom_flvWrap
        ) {
          document.exitFullscreen()
        } else {
          document.requestFullscreen.apply(dom_flvWrap)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ht {
  height: 240px;
}
.screen-camera-item {
  position: relative;
  height: 192px;
  // margin-bottom: 10px;
  // padding: 0 20px;
  > span {
    display: inline-block;
    width: 20px;
    position: absolute;
    top: 2px;
    left: 0;
    color: #fff;
    text-align: center;
  }
  &.width-1 {
    width: 100%;
    /*     height: 100%; */
    border-right: none;
    border-bottom: none;
  }
  &.width-4 {
    width: 50%;
    /*     height: 50%; */
    &:nth-child(2n) {
      border-right: none;
    }
  }
  &.width-6,
  &.width-9 {
    width: 33.3%;
    &:nth-child(3n) {
      border-right: none;
    }
  }
  &.width-12,
  &.width-16 {
    width: 25%;
    &:nth-child(4n) {
      border-right: none;
    }
  }
  &:hover > .screen-camera-delete,
  &:hover > .screen-camera-title,
  &:hover > .video-type {
    visibility: visible;
  }

  .screen-camera-delete {
    position: absolute;
    top: 5px;
    right: 8px;
    z-index: 15;
    text-align: center;
    visibility: hidden;
    i {
      font-size: 0.675rem;
      color: #fff;
    }
  }
  .screen-camera-title {
    height: 28px;
    line-height: 28px;
    background: #000c;
    color: #fff;
    padding: 0 20px 0 5px;
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 2;
    width: calc(100% - 8px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    visibility: hidden;
  }
  .screen-camera-image {
    // border: 1px solid #236da1;
    padding: 4px;
    height: 100%;
    /*background-image: url("../../../assets/images/1.jpeg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: left center;*/
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.screen-item.currentBorder {
  border: 1px solid rgba(212, 34, 10) !important;
}
.video-type {
  position: absolute;
  right: 10px;
  bottom: 10px;
  visibility: hidden;
  z-index: 99;
}
</style>
